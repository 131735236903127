import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate, format = 'dd:hh:mm:ss', onFinishCallback }) => {
    const [timeLeft, setTimeLeft] = useState(getTimeRemaining(targetDate));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(getTimeRemaining(targetDate));
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    function getTimeRemaining(targetDate) {
        const now = new Date().getTime();
        const target = new Date(targetDate).getTime();
        const total = target - now;

        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((total % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds, total };
    }

    function formatTime() {
        const { days, hours, minutes, seconds } = timeLeft;

        if (format === 'hh:mm:ss') {
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }

        return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    if (timeLeft.total <= 0) {
        onFinishCallback();
        return null;
    }

    return <div>{formatTime()}</div>;
};

export default CountdownTimer;
