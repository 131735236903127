import React, {useCallback, useEffect, useState} from "react";
import { initGA, logPageView } from "./assets/analytics";
import {Routes, Route, useLocation, useSearchParams} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Decorations from "./components/Decorations";
import Home from "./pages/Home";
import Order from "./pages/Order";
import OrderPay from "./pages/OrderPay";
import History from "./pages/History";
import Faq from "./pages/Faq";
import Terms from "./pages/Terms";
import Support from "./pages/Support";
import Affilate from "./pages/Affilate";
import {Toaster} from "react-hot-toast";
import {jwtDecode} from "jwt-decode";
import $api from "./api";
import {STATUS_CODES} from "./assets/variables";
import {addLogin, updateSession} from "./redux/actions";
import {useDispatch} from "react-redux";
import axios from "axios";

function App() {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [isAppAvailable, setAppAvailable] = useState(false);

  const handleParseRefValue = () => {
    let ref = searchParams.get("ref");
    if (!ref) return;
    sessionStorage.setItem("ref", ref);
  }

  const getUser = useCallback(async () => {
    try {
      let userId = "0";
      const token =
          localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
      if (token) {
        const decodedToken = jwtDecode(token);
        userId = decodedToken.id;
      }
      let response = await (token ? $api : axios).get(`${process.env.REACT_APP_SERVER_URL}/user/${userId}`);
      if (!response) return;
      if (response.status === STATUS_CODES.SUCCESS) {
        const userData = response.data;
        if (token) dispatch(addLogin(userData.login));
        dispatch(updateSession(userData));
        localStorage.setItem("user", userData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAppAvailable(true);
    }
  },[dispatch]);

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    handleParseRefValue();
  }, [searchParams]);

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
      isAppAvailable
      &&
      <div className="wrapper">
        <div className="content">
          <Header />
          <Decorations />
          <Toaster toastOptions={{style: {width: "500px"}}}/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/order/:transactionId" element={<Order />} />
            <Route path="/order-pay/:transactionId" element={<OrderPay />} />
            <Route path="/history" element={<History />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/support" element={<Support />} />
            <Route path="/affilate" element={<Affilate />} />
          </Routes>
          <Footer />
        </div>
      </div>
  );
}

export default App;
